import React from 'react';


interface IconLinkProps {
    link: string;
    title: string;
    icon: string;
    download?: boolean;
}

export const IconLink: React.FC<IconLinkProps> = ({link, title, icon, download = false}) => {

    return (
        <div className='rounded-full border-2 border-slate-50 px-2 hover:bg-slate-700'>
            <a href={link} download={download ?? false} target={"_blank"} rel="noopener noreferrer">
                <i className={icon}/> <span className='text-[1.25rem]'>{title}</span>
            </a>
        </div>
    );
}
