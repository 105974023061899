import React from 'react';
import {info} from 'info';
import {classNames} from "Helpers";

export const MiniBio = () => {

    return (
        <ul className='flex flex-col gap-2'>
            {info.miniBio.map((bio: { emoji: string, text: string, link?: string }) => {
                return <li key={bio.text}>
                    <div
                        className={classNames('flex gap-3 text-[1.25rem] px-2 text-slate-50', bio.link ? 'hover:bg-slate-700 w-fit rounded-md hover:ml-2 ease-in-out duration-100 cursor-pointer' : '')}>
                        {bio.emoji}
                        {bio.link ? <a href={bio.link}>{bio.text}</a> : <span>{bio.text}</span>}
                    </div>
                </li>
            })}
        </ul>
    );
};
