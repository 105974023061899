import styles from './ScrollSnapContainer.module.scss';
import React, {ReactNode} from "react";

interface ScrollSnapContainerProps {
    children: ReactNode;
}

export const ScrollSnapContainer: React.FC<ScrollSnapContainerProps> = ({children}) => {

    return (
        <div className={styles.container}>
            {children}
        </div>
    );
}
