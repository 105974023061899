import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/index.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Home, Quests} from "./pages";
import './Index.module.scss';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" >
                    <Route index element={<Home/>}/>
                    <Route path="quests" element={<Quests/>}/>
                    <Route path="*" element={<Navigate to="/" replace />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
