import React, {useRef, useState} from "react";
import {info} from "info";
import styles from './ProjectTerminal.module.scss';
import {classNames} from "Helpers";
import Draggable from 'react-draggable';
import {createPortal} from "react-dom";
import {isMobile} from "react-device-detect";

const Draggable1: any = Draggable;

interface ProjectTerminalProps {
    description: string;
    technologies: string;
    awards?: string;
    created: string;
    maxZIndex: number;
    incrementMaxZIndex: () => void;
}

const iconClass = "fa fa-circle hover:cursor-pointer pointer-events-auto hover:opacity-80";
export const ProjectTerminal: React.FC<ProjectTerminalProps> = ({
                                                                    description,
                                                                    technologies,
                                                                    awards,
                                                                    created,
                                                                    maxZIndex,
                                                                    incrementMaxZIndex
                                                                }) => {
    const contentRef = useRef<any>(null);
    const [hidden, setHidden] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const handleZIndex = (element: any) => {
        element.style.zIndex = maxZIndex + 1;
        incrementMaxZIndex();
    };

    // if (isMobile && hidden) {
    //     return null;
    // }

    return <Draggable1 handle='.handle' onStart={() => handleZIndex(contentRef.current)} disabled={isMobile}>
        <div
            className={classNames(styles.terminal, 'min-w-0 md:min-w-[400px] w-full md:w-[20%] rounded-md mb-8 resize pointer-events-auto touch-events-none', !hidden ? styles.shadowed : '', hidden ? styles.hidden : '')}
            ref={contentRef}>
            {expanded && createPortal(
                <div className={classNames('absolute top-0 h-full w-full z-[1000] flex flex-col', styles.terminal)}>
                    <div
                        className={classNames('handle', 'bg-slate-500 p-1 rounded-t-lg text-[1rem]')}>
                        <i className={classNames(iconClass, styles.red)} onClick={() => {
                            setExpanded(false);
                            setHidden(true);
                        }}/>
                        <i className={classNames(iconClass, styles.amber)} onClick={() => {
                            setExpanded(false);
                            setHidden(true);
                        }}/>
                        <i className={classNames(iconClass, styles.green)} onClick={() => setExpanded(false)}/>
                    </div>
                    <div className='p-4 rounded-b-lg bg-[#27242f] text-[1.25rem] flex-grow'>
                        <>
                            <p >
                                {description}
                            </p>
                            <span style={{color: info.baseColor}}>
                            {" @" + created.replaceAll(' ', '-')}
                        </span>
                            <p className={styles.green}>
                        <span className={styles.green} style={{
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                        }}>
                            {technologies.length ? JSON.stringify(technologies.split(', ')).toString().replaceAll(',', ', ') : ''}
                        </span>
                            </p>
                            <span className={styles.awards}>{awards}</span>
                        </>
                    </div>
                </div>,
                document.body
            )}
            <div className={classNames('handle', 'bg-slate-500 p-1 rounded-t-lg text-[1rem] hover:cursor-grab')}>
                <i className={classNames(iconClass, styles.red)} onClick={() => setHidden(true)}/>
                <i className={classNames(iconClass, styles.amber)} onClick={() => setHidden(true)}/>
                <i className={classNames(iconClass, styles.green)} onClick={() => setExpanded(true)}/>
            </div>
            <div className='p-4 rounded-b-lg bg-slate-800 text-[1.25rem] ' onClick={()=>handleZIndex(contentRef.current)}>
                <>
                    <p>
                        {description}
                    </p>
                    <span style={{color: info.baseColor}}>
                            {" @" + created.replaceAll(' ', '-')}
                        </span>
                    <p className={styles.green}>
                        <span className={styles.green} style={{
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                        }}>
                            {technologies.length ? JSON.stringify(technologies.split(', ')).toString().replaceAll(',', ', ') : ''}
                        </span>
                    </p>
                    <span className={styles.awards}>{awards}</span>
                </>
            </div>
        </div>
    </Draggable1>;

}