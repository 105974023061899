import React, {useEffect, useMemo, useRef, useState} from 'react';
import Typist from 'react-typist-component';
import {info} from 'info';

export const Typer = () => {

    const shuffledInfo = useMemo(() => {
        return info.typer
            .map(value => ({value, sort: Math.random()}))
            .sort((a, b) => a.sort - b.sort)
            .map(({value}) => value)
    }, []);


    const [typing, setTyping] = useState(false);
    const infoIndex = useRef(0);


    useEffect(() => {
        setTyping(true);
    }, [typing]);

    return (
        <div className='content-center md:content-start flex'>
            <h3 className='inline-block font-bold text-xl'>
                a
            </h3>
            {typing &&
                <Typist onTypingDone={() => {
                    setTyping(false);
                    infoIndex.current = (infoIndex.current + 1) % info.typer.length
                }}
                        finishDelay={6000}
                        cursor={<span className='text-xl'>|</span>}
                >
                    <h3 className='inline-block whitespace-break-spaces font-bold text-xl'>
                        {shuffledInfo[infoIndex.current]}
                    </h3>
                    <Typist.Backspace count={shuffledInfo[infoIndex.current].length}/>
                </Typist>}
        </div>
    );
};