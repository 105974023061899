import self from "../src/img/self-square.jpg";


export let colors = ["rgb(222,93,93)", "rgb(166,104,255)"];

export const info = {
    firstName: "Tim",
    lastName: "Moser",
    initials: "TDM",
    position: "a Security Analyst",
    selfPortrait: self,
    gradient: `-webkit-linear-gradient(135deg, ${colors})`,
    baseColor: colors[0],
    typer: [
        " coder at heart",
        " full-stack developer",
        " UX designer",
        " database migrator",
        " schema designer",
        " RPi tinkerer",
        " multi-lingual programmer",
        " website creator",
        " web-app engineer",
        " nerd for security",
        "n NLP enthusiast",
        "n AI trainer"
    ],
    miniBio: [
        {
            emoji: '☕',
            text: 'fueled by coffee'
        },
        {
            emoji: '🌎',
            text: 'based in Winterthur, Switzerland'
        },
        {
            emoji: "💼",
            text: "security analyst at modzero"
        },
        {
            emoji: "📧",
            text: "tim.moser@modzero.com",
            link: "mailto:tim.moser@modzero.com"
        },
        {
            emoji: "👨‍💻",
            text: "github.com/timdeanmoser",
            link: "https://www.github.com/TimDeanMoser"
        },
        {
            emoji: "🔍",
            text: "check out CV",
            link: "/files/CV_2023_no_phone.pdf"
        }
    ],
    showcase: [
        {
            title: "Coality",
            description: "Application to assess code comment quality of GitHub repositories",
            live: "https://demo.timdeanmoser.dev",
            thesis: "/files/thesis.pdf",
            source: "https://github.com/TimDeanMoser/coality",
            image: "https://st2.depositphotos.com/3892887/5502/i/600/depositphotos_55024543-stock-photo-curious-koala.jpg"
        },
    ],
    projects: [
        {
            description: "Develop and create architecture for workshop planning web-app 'mokojam' (full-stack, sole developer)",
            technologies: "react, tailwind, nestJS, mariaDB, prisma, AWS S3, docker, gitlab CI/CD",
            awards: "[2023-04-11]\n 1x🥈 2x🥉 Best of Swiss Web",
            created: "Cando-Image",
        },
        {
            description: "Extend Koster Energy Manager with generative AI powered smart help and video call capabilities",
            technologies: "react, styled-components, nestJS, videoSDK",
            awards: "[2023-05-05]\n 1x🥇 2x🥈 2x🥉 Best of Swiss Application",
            created: "Cando-Image",
        },
        {
            description: "Lead back-end developer of high stakes real estate auctioning web-app for CBRE, while being a senior to two near-shore co-workers and helping out in the front-end.",
            technologies: "react, java, spring, mariaDB, AWS S3, docker",
            created: "Cando-Image",
        },
        {
            description: "2 Years extending and " +
                "maintaining issue and " +
                "project management web " +
                "app “myimmopac” " +
                "(full-stack)",
            technologies: "JavaScript, react, mvn, java, " +
                "jetty, postgreSQL, and " +
                "many more",
            created: "immopac",
        },
        {
            description: "Commercial MS-Teams " +
                "chat bot for notifications",
            technologies: "JavaScript, node.js, python, " +
                "flask, MS BotBuilder, oAuth " +
                "2.0, immopac API, GitLab CI",
            created: "immopac internship",
        }, {
            description: "Setup and manage VPC " +
                "server farm and network",
            technologies: "AWS EC2 & VPC",
            created: "Addedo",
        },
        {
            description: "Setup domain for server " +
                "farm including DC & " +
                "Back-up DC, DNS & CA",
            technologies: "AD DS, AWS",
            created: "Addedo",
        },
        {
            description: "Install and setup of multiple " +
                "IBM products",
            technologies: "Cognos Controller, Planning " +
                "Analytics, Cognos Analytics",
            created: "Addedo",
        },
        {
            description: "Development and " +
                "deployment of MS Excel " +
                "Add-In for IBM Cognos",
            technologies: "C#, .NET, WinForm, " +
                "Windows Installer",
            created: "Addedo",
        },
        {
            description: "Analyze and improve " +
                "automatic GitHub issue " +
                "tagger",
            technologies: "Python, java, MEKA, " +
                "fasttext, pandas, Google " +
                "Big Query, nltk, sklearn",
            created: "Software Maintenance and Evolution (SME) UZH",
        },
        {
            description: "Comment quality assessment tool “Coality”",
            technologies: "Python, flask, java, fasttext, " +
                "pandas, GitHub API, nltk, " +
                "sklearn",
            created: "Bachelor Thesis",
        },
        {
            description: "Web Scraper",
            technologies: "Python, Requests, " +
                "Selenium, Google " +
                "Books API, NYT API",
            created: "Social Computing UZH",
        },
        {
            description: "Interactive Data " +
                "Visualization of EBIRD data",
            technologies: "Python, pandas, bokeh",
            created: "Data Visualization Concepts UZH",
        },
        {
            description: "Chess, Ladders and Snakes " +
                "Games",
            technologies: "C, CLI, Git",
            created: "Software Construction UZH",
        },
        {
            description: "Pokémon Top Trumps Card Game (Back-End)",
            technologies: "Java, SpringBoot, REST, Git, " +
                "Jira, Heroku, SonarCube, " +
                "Postman, UML, Figma, " +
                "Gradle, PokéAPI",
            created: "Software Praktikum UZH",
        },
        {
            description: "Processor Emulator",
            technologies: "C++",
            created: "Informatics 1 ETHZ",
        },
        {
            description: "Midi Notes Player",
            technologies: "Field Programmable Gate " +
                "Array (FPGA)",
            created: "Digitaltechnik Praktikum ETHZ",
        },
        {
            description: "Extend Coality and host as " +
                "full-stack web app",
            technologies: "Python, flask, JavaScript, " +
                "react, docker, GitHub CI",
            created: "Leisure Time",
        },
        {
            description: "Basic penetration testing " +
                "on home network",
            technologies: "Python, Kali Linux & some " +
                "of its tools like nmap, " +
                "aircrack-ng, etc.",
            created: "Leisure Time",
        },
        {
            description: "Chrome password stealing " +
                "thumb drive",
            technologies: "Rubber ducky script, " +
                "Digispark, PowerShell, " +
                "SQLite",
            created: "Leisure Time",
        },
        {
            description: "World of Warcraft bot",
            technologies: "Lua macros, AutoClicker " +
                "Script",
            created: "Leisure Time",
        },
        {
            description: "Reddit give-away QR-Code " +
                "image scraper with " +
                "automatic submission",
            technologies: "Reddit API, openCV, " +
                "python, Pillow",
            created: "Leisure Time",
        },
        {
            description: "AI to win basic game using " +
                "Machine Learning",
            technologies: "Python, pygame, genetic " +
                "algorithm",
            created: "Leisure Time",
        },
        {
            description: "Basic platformer game " +
                "prototype",
            technologies: "C#, Unity game engine",
            created: "Leisure Time",
        },
        {
            description: "DIY Screen Ambient Lights",
            technologies: "Arduino, C#, soldered " +
                "LED-Strip",
            created: "Leisure Time",
        },
        {
            description: "Setup recursive DNS in " +
                "home network",
            technologies: "RaspberryPi, Pi-Hole",
            created: "Leisure Time",
        },
        {
            description: "Guide next year’s SME " +
                "student group’s project at " +
                "UZH",
            technologies: "",
            created: "Leisure Time",
        },
        {
            description: "Setup and host website " +
                "timdeanmoser.dev",
            technologies: "JavaScript, react, SASS, " +
                "Ubuntu, DNS, GitHub CI",
            created: "Leisure Time",
        },
        {
            description: "Create custom split ergonomic keyboard and learn new 'miryoku' layout",
            technologies: "Soldering, 3D-Printing, KiCad, QMK",
            created: "Leisure Time",
        },
    ],
    quests: [
        "5 Lüt es Kompliment mache.",
        "5 Lüt sege sie hend öpis am Bagge.",
        "5 Mal Anke anstatt Butter sege.",
        "5 Mal lache wie de Samichlaus (ho ho ho).",
        "5 Mal en ganze Satz uf Englisch sege.",
        "5 Mal am Math en falsche Name sege (Matthias, Manuel,...) (ohni Übernäme).",
        "5 Mal \"ai caramba\" lut sege.",
        "5 Mal lut \"das Grosse Backen\" sege.",
        "5 Lüt sege sie hend eppis zwüsched de Zäh.",
        "5 Mal sege, dass de Jan es ganz herzigs Chind gsi isch.",
        "5 Mal eppert mit vollem Name (Vor- / Mittel-/ und Nachname) ahspreche oder erwähne.",
        "3 Mal vom Rebeliechtliumzug schwärme.",
        "3 Mal sich bi Lüüt wommer scho kennt go (namal) vorstelle.",
        "bi 3 Lüt go fröge \"wie isch namal din Name?\", wommer scho kennt.",
        "2 Mal es Wiehnachtsliedli ahstimme.",
        "3 Lüt fröge, was ihres Lieblingstier isch.",
        "zu 2 Ziite go 5 Ligestütz mache.",
        "5 mal sege, dass eppert en Schatz isch.",
        "3 mal eh leeri Bierdose ade Stirn vertrucke.",
        "5 mal anstatt \"Danke\", \"Banke\" sege.",
        "3 mal uf en Gegestand zeige und fröge \"is it cake?\".",
        "5 Lüt fröge was ihres lieblings guezli isch.",
        "2 Lüt dezue bringe en shots znäh, will sie dini Quest nid erratet hend.",
        "mit 2 verschidene Lüt en neue Handshake ihstudiere.",
        "3 mal en einminütige Hitzgi fake.",
        "20 Minute lang zwei Getränk glichzitig i beidne Händ hebe.",
        "5 Lüt dezue bringe \"Milch\" z säge.",
        "5 Mal eppis sege wo sich reimt.",
        "3 Mal sege \"Oh stimmt ich muess morn no Brot poste\".",
        "Eppert 3 mal in 5 Minute fröge wie spaat dases isch.",
        "3 Mal \"Hokus Pokus Fiddibus\" ine Konversation ihflüsse lah.",
    ]
}
