import {GradientImage, MiniBio, ProjectTerminal, Salutation, Showcase} from "components";
import React, {useRef, useState} from "react";
import me from 'img/self-square.jpg';
import {Typer} from "../components/Typer";
import {ScrollSnapContainer, ScrollSnapSection} from "../components/ScrollSnap";
import {info} from "../info";

export const Home: React.FC = () => {
    const section1 = useRef(null);
    const section2 = useRef(null);
    const section3 = useRef(null);
    const [terminalsMaxZIndex, setTerminalMaxZIndex] = useState(info.projects.length);
    const scrollTo = (section: { current: any }) => {
        section.current.scrollIntoView({behavior: "auto"});
    };
    return <div className='bg-slate-900 text-slate-50'>
        <ScrollSnapContainer>
            <ScrollSnapSection scrollTo={scrollTo} sectionRef={section1} goToSectionRef={section2} grow>
                <div
                    className='flex flex-row mx-auto gap-4 flex-wrap px-8 justify-center content-center h-full min-h-[calc(100vh-128px)] pt-4'>
                    <GradientImage src={me}/>
                    <div className='flex flex-col gap-2'>
                        <Salutation/>
                        <Typer/>
                        <MiniBio/>
                    </div>
                </div>
            </ScrollSnapSection>
            <ScrollSnapSection scrollTo={scrollTo} sectionRef={section2} goToSectionRef={section3} grow>
                <div className='flex flex-col gap-4 h-full px-8 pt-16 min-h-[calc(100vh-128px)]'>
                    <h1 className='text-center text-[2rem] md:text-[3rem] font-bold'>Thesis Showcase</h1>
                    <div className='h-full flex justify-center'>
                        {
                            info.showcase.map((project) => {
                                return <Showcase key={project.title} image={project.image} demo={project.live}
                                                 source={project.source} thesis={project.thesis}
                                                 title={project.title} description={project.description}/>
                            })
                        }

                    </div>

                </div>
            </ScrollSnapSection>
            <ScrollSnapSection scrollTo={scrollTo} sectionRef={section3} goToSectionRef={section1} arrowUp grow>
                <div className='flex flex-wrap flex-col gap-4 h-full px-8 pt-4'>
                    <h1 className='text-center text-[2rem] md:text-[3rem] font-bold'>Previous Projects</h1>
                    <div className='flex gap-x-2 flex-wrap justify-center'>
                        {
                            info.projects.map((project) => {
                                return <ProjectTerminal key={project.description} {...project}
                                                        maxZIndex={terminalsMaxZIndex}
                                                        incrementMaxZIndex={() => setTerminalMaxZIndex(terminalsMaxZIndex + 1)}/>
                            })
                        }

                    </div>
                </div>
            </ScrollSnapSection>
        </ScrollSnapContainer>
    </div>
}
