import React from "react";
import {info} from "info";
import styles from './Salutation.module.scss';

export const Salutation: React.FC = () => {
    return <div className='text-[3rem] font-bold -mb-4'>
        <h1>Hi, I'm <span style={{
            background: info.gradient,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        }}>{info.firstName}</span><span className={styles.hand}>🤚</span>
        </h1>
    </div>;
}