import React from "react";
import {info} from "info";
import {GradientImage} from "./GradientImage";
import {IconLink} from "./IconLink";

export const Showcase: React.FC<{ image: any, demo: string, source: string, thesis?: string, title: string, description: string }> = ({
                                                                                                                                          image,
                                                                                                                                          demo,
                                                                                                                                          source,
                                                                                                                                          thesis,
                                                                                                                                          title,
                                                                                                                                          description
                                                                                                                                      }) => {
    return (
        <div className='flex items-center justify-center gap-4 flex-col md:flex-row'>
            <GradientImage src={image}/>
            <div className='flex flex-col gap-2 text-center md:text-start items-center md:items-start max-w-[400px]'>
                <h1 className='text-[1.5rem] font-bold'>{title}</h1>
                <h4 className='text-[1.25rem]'>{description}</h4>
                    <IconLink link={demo} title={'Live Demo'} icon={'fa fa-safari'}/>
                    <IconLink link={source} title={'Source Code'} icon={'fa fa-code'}/>
                {
                    thesis && (
                            <IconLink link={thesis} title={'Thesis'} icon={'fa fa-book'}/>
                    )
                }
            </div>
        </div>
    );
}