import React, {ReactNode, useRef} from "react";
import {BsChevronCompactDown} from "react-icons/bs";
import {classNames} from "../../../Helpers";

interface ScrollSnapSectionProps {
    scrollTo: any;
    sectionRef: any;
    goToSectionRef: any;
    children: ReactNode;
    grow?: boolean;
    showArrow?: boolean;
    arrowUp?: boolean;
}

export const ScrollSnapSection: React.FC<ScrollSnapSectionProps> = ({
         scrollTo,
         sectionRef,
         goToSectionRef,
         children,
         showArrow = true,
    grow=false,
    arrowUp = false,
     }) => {
        return (
            <div className={classNames('flex flex-col items-center content-center w-full snap-start min-h-[100vh]', grow?'':'h-[100vh] ')} ref={sectionRef}>
                {children}
                {showArrow && (
                    <button type='button'
                            className='flex-grow-0 bottom-0 w-full h-16 text-slate-500 cursor-pointer hover:text-slate-50 bg-slate-800 rounded-md group mt-16'
                            onClick={() => scrollTo(goToSectionRef)}>
                        <BsChevronCompactDown className={classNames('w-16 h-16 mx-auto group-hover:pt-2 ease-in-out duration-500', arrowUp ? 'rotate-180':'')}/>
                    </button>
                )}
            </div>
        );
    }