import React from "react";
import {info} from "../info";

const getRandomQuest = () => {
        return info.quests[Math.floor(Math.random() * info.quests.length)];
};

export const Quests: React.FC = () => {

    return <div className='bg-slate-900 text-slate-50 flex items-center justify-center py-12 px-4 flex flex-col gap-8'>
        <p className='text-[2rem]'>Samichlaus Quest</p>
        <div className='w-full max-w-[480px] h-full bg-white text-black rounded-md p-4 flex flex-col gap-4'>
            <p className='text-lg'>Du muesch ...</p>
            <p className='text-lg'>{getRandomQuest()}</p>
            <button className='bg-slate-700 text-lg text-white p-2 rounded-md' onClick={()=>window.location.reload()}>Neui Quest</button>
        </div>
        <p className='text-[1.5rem] mt-4 mb-[-8px]'>Regle</p>
        <p className='text-md max-w-[480px]'>De Samichlaus verlaht sich uf din Ihsatz aber phalte dini Ufgab für dich! <br/><br/>Söttisch du ufflüge, muesch als Straf trinke<br/><br/>Söttisch du anderi SpielerInne ahschuldige, ohne dere ihri Quest richtig z'errate, wird ebefalls trunke <br/><br/>Vil Spass!</p>
    </div>
}
